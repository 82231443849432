import React from 'react';
// ** Next Imports
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';

// ** Loader Import
import NProgress from 'nprogress';

// ** Emotion Imports
import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

// ** Config Imports
import 'src/configs/i18n';
import themeConfig from '../configs/themeConfig';

// ** Third Party Import
import { Toaster } from 'react-hot-toast';

// ** Component Imports
import WindowWrapper from '../@core/components/window-wrapper';
import ThemeComponent from '../@core/theme/ThemeComponent';

// ** Contexts
import {
  SettingsConsumer,
  SettingsProvider,
} from '../@core/context/settingsContext';

// ** Styled Components
import ReactHotToast from '../@core/styles/libs/react-hot-toast';

// ** Utils Imports
import { createEmotionCache } from '../@core/utils/create-emotion-cache';

// ** Prismjs Styles
import 'prismjs';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';
import 'prismjs/themes/prism-tomorrow.css';

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'react-image-gallery/styles/css/image-gallery.css';

// ** Global css styles
import '../../styles/globals.css';
import '../@carketa/sales-packet/CarketaValuation.css';

import { SessionProvider } from 'next-auth/react';
import { AppProvider } from 'src/@carketa/app/AppContext';

// import { CrispWithNoSSR } from 'src/@carketa/components/crisp/CrispNoSsr';
import { trpc } from '../utils/trpc';

import { GoogleTagManager } from '@next/third-parties/google';
import { AnalyticsWrapper } from 'src/@carketa/analytics';

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });
  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });
}
const isDevelopment = process.env.NODE_ENV === 'development';

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const isPdfMode = props?.router?.query?.pdfMode === 'true';

  // @ts-ignore
  return (
    <>
      <SessionProvider session={pageProps.session}>
        <CacheProvider value={emotionCache}>
          <AppProvider>
            <Head>
              <title>Carketa</title>
              <meta name="description" content="Carketa" />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/img/icons/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/img/icons/favicon-32x32.png?v=2"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/img/icons/favicon-16x16.png?v=2"
              />
              <link rel="manifest" href="/img/icons/site.webmanifest" />
              <link
                rel="mask-icon"
                href="/img/icons/safari-pinned-tab.svg"
                color="#5bbad5"
              />
              <link rel="shortcut icon" href="/img/icons/favicon.ico?v=2" />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta
                name="msapplication-config"
                content="/img/icons/browserconfig.xml"
              />
              <meta name="theme-color" content="#ffffff"></meta>
              <script
                type="text/javascript"
                async
                defer
                src="https://snapshot.carfax.com/latest/dealer-snapshot.js"
              ></script>
              {!isPdfMode && (
                <script
                  type="text/javascript"
                  id="hs-script-loader"
                  async
                  defer
                  src="//js-na1.hs-scripts.com/7261664.js"
                ></script>
              )}
              {!isDevelopment && (
                <>
                  <script
                    async
                    src="https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz"
                  />
                  <script
                    async
                    src="https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.22-min.js.gz"
                  />
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                      (function (g, u, i, d, e, s) {
                        g[e] = g[e] || [];
                        var f = u.getElementsByTagName(i)[0];
                        var k = u.createElement(i);
                        k.async = true;
                        k.src =
                          'https://static.userguiding.com/media/user-guiding-' +
                          s +
                          '-embedded.js';
                        f.parentNode.insertBefore(k, f);
                        if (g[d]) return;
                        var ug = (g[d] = { q: [] });
                        ug.c = function (n) {
                          return function () {
                            ug.q.push([n, arguments]);
                          };
                        };
                        var m = [
                          'previewGuide',
                          'finishPreview',
                          'track',
                          'identify',
                          'hideChecklist',
                          'launchChecklist',
                        ];
                        for (var j = 0; j < m.length; j += 1) {
                          ug[m[j]] = ug.c(m[j]);
                        }
                      })(window, document, 'script', 'userGuiding', 'userGuidingLayer', 'YDI90571WZNID');
                      `,
                    }}
                  />
                </>
              )}
              <meta
                name="viewport"
                content="width=device-width, user-scalable=no"
              />
            </Head>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <WindowWrapper>
                        <AnalyticsWrapper>
                          <Component {...pageProps} />
                        </AnalyticsWrapper>
                      </WindowWrapper>
                      <ReactHotToast>
                        <Toaster
                          position={settings.toastPosition}
                          toastOptions={{ className: 'react-hot-toast' }}
                        />
                      </ReactHotToast>
                    </ThemeComponent>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </AppProvider>
        </CacheProvider>
      </SessionProvider>
      {/*<CrispWithNoSSR />*/}
      <GoogleTagManager gtmId="G-E47B7YK2Z1" />
    </>
  );
};

export default trpc.withTRPC(App);
